<template>
  <div class="container h-100">
    <!-- <div>all</div> -->
    <div class="row h-100 justify-content-center align-items-center">
      <div class="col-lg-5">
        <div class="card card-custom shadow">
          <div class="card-body p-0">
            <div class="row">
              <div class="col-lg-12 my-5">
                <div class="mx-4">
                  <div class="my-5">
                    <span class="icon status-icon cone-icon"></span>
                  </div>

                  <h4 class="text-center fw-bolder text-navy-blue">
                    It appears your house is located in a village/subdivision.
                  </h4>
                  <div class="text-center mt-3">
                    <!-- <strong>Sorry.</strong> -->
                    <!-- Your area is not serviceable as of the moment. Rest assured
                    that we will do our best to increase our coverage. -->

                    <!-- Your area might not be serviceable at the moment. Please tap
                    <b>SELECT LOCATION</b> to provide us with a more specific
                    area. -->
                    We need more information from you to get your exact
                    location. Please click continue.
                  </div>

                  <!-- <div class="text-center mt-3">
                    Select
                    <a href="#" @click.prevent="showLocation()">location</a>
                    manually.
                  </div> -->

                  <div class="my-5 text-center">
                    <button
                      class="btn btn-s2s-blue me-2 px-5"
                      @click.prevent="showLocation()"
                    >
                      Continue
                    </button>
                    <button
                      class="btn px-5 btn-s2s-blue"
                      @click.prevent="goBack()"
                    >
                      Back
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <LocationModal ref="locationModal" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
// import ModalMaps from "../components/ModalMaps.vue";
import LocationModal from "../components/LocationModal.vue";
export default {
  components: {
    // ModalMaps,
    LocationModal,
  },

  data() {
    return {
      modalMaps: {
        isShow: false,
      },

      accountNumber: "",
      submitted: false,
    };
  },

  methods: {
    goBack() {
      this.$store.dispatch("ADD_NOT_SERVICEABLE", false).then(() => {
        this.$router.push({ path: "/" });
      });
    },

    reFormatAccountNumber(num) {
      return num.replace(/\s+/g, "").replace("+", "");
    },

    async showLocationModal() {
      await this.$refs.locationModal.open();
    },

    showLocation() {
      this.showLocationModal();
    },
  },

  created() {},

  validations: {
    accountNumber: {
      required,
    },
  },
};
</script>

<style scoped>
/* @import "../assets/css/reset.css";
@import "../assets/css/sweetalert2.min.css";
@import "../assets/css/superwheel.min.css"; */
</style>
