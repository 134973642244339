<template>
  <div
    class="modal fade modal-data-pack"
    ref="modalNotice"
    data-bs-keyboard="true"
    data-bs-backdrop="static"
    tabindex="-1"
    aria-hidden="true"
  >
    <div class="modal-dialog modal-dialog-centered">
      <div class="modal-content">
        <form action="">
          <div class="modal-header pb-1">
            <h5 class="text-blue">Select Location</h5>
            <button
              type="button"
              class="btn-close"
              data-bs-dismiss="modal"
              aria-label="Close"
            ></button>
          </div>
          <div class="modal-body">
            <div class="row">
              <div class="col-12">
                <div class="mb-3">
                  <label class="form-label">First Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="First Name"
                    v-model="form.firstName"
                    autocomplete="null"
                    name="firstName"
                    :class="{
                      'is-invalid': submitted && errors.has('firstName'),
                    }"
                    v-validate="'required'"
                  />
                  <div
                    v-if="submitted && errors.has('firstName')"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Middle Name</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Middle Name"
                    v-model="form.middleName"
                    name="middleName"
                  />
                </div>

                <div class="mb-3">
                  <label class="form-label">Last Name *</label>
                  <input
                    type="text"
                    class="form-control"
                    placeholder="Last Name"
                    v-model="form.lastName"
                    name="lastName"
                    :class="{
                      'is-invalid': submitted && errors.has('lastName'),
                    }"
                    v-validate="'required'"
                  />
                  <div
                    v-if="submitted && errors.has('lastName')"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                </div>
                <div class="mb-3">
                  <label class="form-label">Region *</label>
                  <select
                    class="form-control"
                    name="region"
                    :class="{
                      'is-invalid': submitted && errors.has('region'),
                    }"
                    v-validate="'required'"
                    @change.prevent="regionSelected($event)"
                    :disabled="location.regions < 1"
                    v-model="form.region"
                  >
                    <option value="" selected disabled hidden>
                      Select Region
                    </option>
                    <option
                      v-for="(region, i) in location.regions"
                      :key="i"
                      :value="region.name"
                      :data-id="region._id"
                      v-text="region.name"
                    ></option>
                  </select>
                  <div
                    v-if="submitted && errors.has('region')"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Province *</label>
                  <select
                    class="form-control"
                    name="province"
                    :class="{
                      'is-invalid': submitted && errors.has('province'),
                    }"
                    v-validate="'required'"
                    @change.prevent="provinceSelected($event)"
                    :disabled="location.provinces < 1"
                    v-model="form.province"
                  >
                    <option value="" selected disabled hidden>
                      Select Province
                    </option>
                    <option
                      v-for="(province, i) in location.provinces"
                      :key="i"
                      :value="province.name"
                      :data-id="province._id"
                      v-text="province.name"
                    ></option>
                  </select>
                  <div
                    v-if="submitted && errors.has('province')"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">City / Municipal *</label>
                  <select
                    class="form-control"
                    name="city"
                    :class="{
                      'is-invalid': submitted && errors.has('city'),
                    }"
                    v-validate="'required'"
                    @change.prevent="citySelected($event)"
                    :disabled="location.cities < 1"
                    v-model="form.city"
                  >
                    <option value="" selected disabled hidden>
                      Select City
                    </option>
                    <option
                      v-for="(city, i) in location.cities"
                      :key="i"
                      :value="city.name"
                      :data-id="city._id"
                      v-text="city.name"
                    ></option>
                  </select>
                  <div
                    v-if="submitted && errors.has('city')"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Barangay *</label>
                  <select
                    class="form-control"
                    name="barangay"
                    :class="{
                      'is-invalid': submitted && errors.has('barangay'),
                    }"
                    v-validate="'required'"
                    :disabled="location.barangay < 1"
                    v-model="form.barangay"
                    @change.prevent="barangaySelected($event)"
                  >
                    <option value="" selected disabled hidden>
                      Select Barangay
                    </option>
                    <option
                      v-for="(brgy, i) in location.barangay"
                      :key="i"
                      :value="brgy.name"
                      :data-id="brgy._id"
                      v-text="brgy.name"
                    ></option>
                  </select>
                  <div
                    v-if="submitted && errors.has('barangay')"
                    class="invalid-feedback"
                  >
                    This field is required
                  </div>
                </div>

                <div class="mb-3">
                  <label class="form-label">Subdivision</label>
                  <select
                    class="form-control"
                    name="subdivision"
                    :disabled="location.subdivisions < 1"
                    v-model="form.subdivision"
                  >
                    <option value="" selected disabled hidden>
                      Select Subdivision
                    </option>
                    <option
                      v-for="(subd, i) in location.subdivisions"
                      :key="i"
                      :value="subd.name"
                      :data-id="subd._id"
                      v-text="subd.name"
                    ></option>
                  </select>
                </div>

                <div class="mb-3">
                  <label class="form-label">Building</label>
                  <select
                    class="form-control"
                    name="building"
                    :disabled="location.buildings < 1"
                    v-model="form.building"
                  >
                    <option value="" selected disabled hidden>
                      Select Building
                    </option>
                    <option
                      v-for="(buil, i) in location.buildings"
                      :key="i"
                      :value="buil.name"
                      :data-id="buil._id"
                      v-text="buil.name"
                    ></option>
                  </select>
                </div>
              </div>
            </div>
          </div>
          <div class="modal-footer">
            <!-- <button type="button" class="btn btn-s2s-blue mr-2 px-5">
              Submit
            </button> -->
            <button
              class="btn px-5 mr-2 btn-s2s-blue"
              :disabled="btnLoading"
              @click.prevent="onSubmit()"
            >
              <span
                v-if="btnLoading"
                class="spinner-border spinner-border-sm pl-5"
                role="status"
              >
                <span class="visually-hidden">Loading...</span>
              </span>
              Submit
            </button>
            <button type="button" class="btn btn-link" data-bs-dismiss="modal">
              Close
            </button>
          </div>
        </form>
      </div>
    </div>
  </div>
</template>

<script>
import { Modal } from "bootstrap";
import { required } from "vuelidate/lib/validators";

export default {
  data() {
    return {
      location: {
        regions: [],
        provinces: [],
        cities: [],
        barangay: [],
        subdivisions: [],
        buildings: [],
      },

      form: {
        firstName: "",
        middleName: "",
        lastName: "",
        address: "",
        barangay: "",
        city: "",
        region: "",
        province: "",
        subdivision: "",
        building: "",
      },

      modal: null,
      resolve: null,
      reject: null,

      submitted: false,
      btnLoading: false,
    };
  },
  methods: {
    open() {
      this.modal.show();

      // this.title = title;
      // this.message = message;
      // this.options = Object.assign(this.options, options);
      return new Promise((resolve, reject) => {
        this.resolve = resolve;
        this.reject = reject;
      });
    },

    agree() {
      this.resolve(true);
      // this.dialog = false;
      this.modal.hide();
    },
    cancel() {
      this.resolve(false);
      // this.dialog = false;
      this.modal.hide();
    },

    async getLocation(base = "regions", params = "") {
      await this.$location
        .get(`${base}?${params}`)
        .then((response) => {
          const res = response.data.data;
          if (base === "regions") {
            this.location.regions = res.regions;
          }
          if (base === "provinces") {
            this.location.provinces = res.provinces;
          }
          if (base === "cities") {
            this.location.cities = res.cities;
          }
          if (base === "barangay") {
            this.location.barangay = res.baranggay;
          }

          if (base === "buildings") {
            this.location.buildings = res.buildings;
          }

          if (base === "subdivisions") {
            this.location.subdivisions = res.subdivision;
          }

          // return (res);
        })
        .catch((e) => {
          console.log(e);
          this.loadingBtn = false;
        });
    },

    regionSelected(e) {
      const regionId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("provinces", `regionId=${regionId}`);
      this.form.province = "";
      this.location.provinces = [];
      this.form.city = "";
      this.location.cities = [];
      this.form.barangay = "";
      this.location.barangay = [];
      this.form.subdivision = "";
      this.location.subdivisions = [];
      this.form.building = "";
      this.location.buildings = [];
    },

    provinceSelected(e) {
      const provinceId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("cities", `provinceId=${provinceId}`);
      this.location.cities = [];
      this.location.barangay = [];
      this.location.subdivisions = [];
      this.location.buildings = [];
      this.form.city = "";
      this.form.barangay = "";
      this.form.subdivision = "";
      this.form.building = "";
    },

    citySelected(e) {
      const cityId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("barangay", `cityId=${cityId}`);
      // this.form.city = ""
      this.location.barangay = [];
      this.location.subdivisions = [];
      this.location.buildings = [];
      this.form.barangay = "";
      this.form.subdivision = "";
      this.form.building = "";
    },

    barangaySelected(e) {
      const brgyId = e.target ? e.target.selectedOptions[0].dataset.id : e;
      this.getLocation("buildings", `barangayId=${brgyId}`);
      this.getLocation("subdivisions", `barangayId=${brgyId}`);
      this.location.subdivisions = [];
      this.location.buildings = [];
      this.form.subdivision = "";
      this.form.building = "";
    },

    async onSubmit() {
      this.submitted = true;
      this.btnLoading = true;
      const s = await this.$validator.validate().then((valid) => {
        return valid;
      });

      if (!s) {
        this.btnLoading = false;
        return false;
      }

      const { firstName, lastName, building, subdivision } = this.form;
      const { lat, lng } = this.getCenter;
      const payload = {
        firstName: firstName,
        lastName: lastName,
        lat: lat,
        long: lng,
        building: building,
        subdivision: subdivision,
        online: true,
      };

      await this.$api
        .post("/acquisition/port-reservation", payload)
        .then((response) => {
          const res = response.data;
          this.submitted = false;
          this.btnLoading = false;
          if (!res.status) {
            this.$swal("Error", res.message, "error");
            return;
          }

          this.$store.dispatch("ADD_REFNUMBER", res.data.referenceNumber);

          this.$store
            .dispatch("ADD_APPLICATIONFORMDATA", this.form)
            .then(() => {
              this.$router.push({ path: "application-form" }).then(() => {
                this.cancel();
              });
            });
          // this.loader.loader.hide();
          // if (res.status) {
          //   this.$router.push({ path: "/" });
          // }
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
  mounted() {
    this.modal = new Modal(this.$refs.modalNotice, {
      keyboard: false,
    });
  },

  computed: {
    getCenter() {
      return this.$store.getters.GET_CENTER;
    },
  },

  created() {
    // this.getAvailableAreas();
    this.getLocation();
  },

  validations: {
    form: {
      firstName: {
        required,
      },
      lastName: {
        required,
      },
      barangay: {
        required,
      },
      city: {
        required,
      },
      region: {
        required,
      },
      province: {
        required,
      },
    },
  },
};
</script>
